class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.token = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  getUserName() {
    let userObj = JSON.parse(localStorage.getItem("user"));
    return userObj.user.clientName;
  }

  updateAdminAccounts(newCompany) {
    let currentAdmin = JSON.parse(localStorage.getItem("user"));
    currentAdmin.user.companies.push(newCompany);
    localStorage.setItem("user", JSON.stringify(currentAdmin));
  }

  async updateCompanies(activeCompanies) {
    let currentCompanies = JSON.parse(localStorage.getItem("user"));
    currentCompanies.user.companies = activeCompanies;
    return localStorage.setItem("user", JSON.stringify(currentCompanies));
  }

  updateFeedback(feedback) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.user.feedback = feedback;
    localStorage.setItem("user", JSON.stringify(user));
  }
}

export default new TokenService();
