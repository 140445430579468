<template>
  <v-app>
    <ToasterAlert />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import ToasterAlert from "./components/ToasterAlert.vue";
import { ref, watchEffect, onMounted } from "vue";
import { useRoute } from "vue-router";
import TokenService from "@/services/token.service";

export default {
  name: "App",
  components: { ToasterAlert },

  setup() {
    let currentRouteName = ref("");
    const route = useRoute();

    const configureScrollBar = () => {
      let currentUser = TokenService.getUser();
      if (currentUser) {
        const bodyElement = document.querySelector("body");
        if (currentUser.user.type == "admin") {
          bodyElement.classList = ["main-scroll-admin"];
        } else {
          bodyElement.classList = ["main-scroll-default"];
        }
      }
    };

    watchEffect(() => {
      if (currentRouteName.value != route.name) {
        currentRouteName.value = route.name;
        configureScrollBar();
      }
    });

    onMounted(async () => {
      configureScrollBar();
    });
  }
};
</script>

<style>
</style>
