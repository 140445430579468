<template>
  <v-snackbar
    v-model="toasterAlert.getToaster.displayToaster"
    fixed
    location="top right"
    :color="toasterAlert.getToaster.toastColor"
    :min-width="100"
    :max-width="360"
    class="toaster-container"
  >
    <span class="font-weight-black">{{ toasterAlert.getToaster.toastMessage }}</span>

    <span class="ml-3 add-cursor-pointer" @click="toasterAlert.resetToaster">
      <v-icon color="#ffffff" size="20">{{ toasterAlert.getToaster.toastIcon }}</v-icon>
    </span>
  </v-snackbar>
</template>

<script>
import { ref } from "vue";
import { useToasterAlert } from "../store/ToasterAlert";

export default {
  name: "Toaster Alert",

  setup() {
    const showToaster = ref(true);
    const toasterAlert = useToasterAlert();

    return { showToaster, toasterAlert };
  }
};
</script>

<style lang="css">
.toaster-container span.font-weight-black {
  font-weight: 500 !important;
}
</style>
