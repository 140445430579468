import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "./assets/global.scss";
import TokenService from "./services/token.service";
import { useToasterAlert } from "./store/ToasterAlert.js";
import axios from "axios";
import { io } from "socket.io-client";

// import LocalCurrencyService from "./services/localCurrency.service";

// Package used for Converting Data Tables To CSV
// https://github.com/Belphemur/vue-json-csv

// Vue 3 Apex Charts Documentation
// https://github.com/apexcharts/vue3-apexcharts

// axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT;
// Using this as the Base URL for now.
const baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = baseURL;

const socket = io(`${baseURL}`);

socket.on("connect", () => {
  console.log(`You are connected with Socket_id: ${socket.id}`);
});

socket.on("connect_error", (err) => {
  // the reason of the error, for example "xhr poll error"
  console.log(`E 1-a: ${err.message}`);

  // some additional description, for example the status code of the initial HTTP response
  console.log(`E 1-b: ${err.description}`);

  // some additional context, for example the XMLHttpRequest object
  console.log(`E 1-c: ${err.context}`);
});

axios.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    // const currency = LocalCurrencyService.getLocalCurrency()
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      // if(!config.url.includes('https://openexchangerates.org')){
      //   config.headers['exchange-rate'] = currency.currencyMultiplier
      //   config.headers['currency'] = currency.currency_code
      // }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      useToasterAlert().setToaster({
        message: `${error?.response?.data?.message}`,
        icon: "mdi-close-circle-outline",
        color: "#B00020",
        display: true,
      });

      TokenService.removeUser();
      if (router.currentRoute.value.name != "Login") {
        return router.push({ name: "Login" });
      } else {
        throw error;
      }
    }

    throw error;
  }
});

loadFonts();

createApp(App)
  .use(createPinia())
  .use(router)
  .use(store)
  .use(vuetify)
  .mount("#app");
